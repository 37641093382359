import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import flag from "../../assets/images/flag.png";
import getScanHistory from "../../functions/eventScanner/getScanHistory";
import { UserAccessTokenState } from "../../recoil/user";
import getDateFormatted from "../../utils/getDateFormatted";
import translate from "../../utils/temp/translate";
import { StatDetails } from "../customers/StatDetails";
import CircularLoader from "../general/CircularLoader";

import styles from "../../styles/eventScanner/ScannedTickets.module.css";
import gridStyles from "../../styles/general/Grid.module.css";

export default function ScannedTickets({ eventId }) {
  const [scanHistory, setScanHistory] = useState([]);
  const accessToken = useRecoilValue(UserAccessTokenState);
  const [loading, setLoading] = useState(false);
  const voidCount = scanHistory?.reduce(
    (acc, next) => (next.secondScanTime ? 1 : 0) + acc,
    0
  );

  useEffect(() => {
    if (!loading) {
      (async () => {
        try {
          setLoading(true);
          const resp = await getScanHistory(accessToken, eventId);
          setScanHistory(resp);
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [eventId]);
  return (
    <div>
      <div className={`${gridStyles.headersection} flex al-ce jc-sp`}>
        <div>{translate("Total Tickets Double Scanned")}</div>
      </div>
      {loading ? (
        <div className={styles.loaderContainer}>
          <div className={styles.loader}>
            <CircularLoader loading={true} />
          </div>
        </div>
      ) : (
        <>
          <div className={styles.container}>
            <StatDetails
              iconSrc={flag}
              header={voidCount || 0}
              subtext={"Total Ticket Void"}
            />
          </div>
          <GridData data={scanHistory} />
        </>
      )}
    </div>
  );
}

const GridData = ({ data = [] }) => {
  return (
    <div className={styles.gridData}>
      <div className={styles.headers}>
        <div>Categories</div>
        <div>Section</div>
        <div>Seat</div>
        <div>Buyer</div>
        <div>First Scan Time</div>
        <div>Second Scan Time</div>
      </div>
      {data?.map((scan, index) => (
        <div className={styles.values} key={index}>
          <div>{scan?.category}</div>
          <div>{scan?.section}</div>
          <div>
            Row {scan?.row}, {scan?.seat}
          </div>
          <div>{scan?.buyerName}</div>
          <div>
            {getDateFormatted(new Date(scan?.firstScanTime).toISOString())}
          </div>
          <div className="red">
            {scan?.secondScanTime
              ? `${getDateFormatted(
                  new Date(scan?.secondScanTime).toISOString()
                )}(VOID)`
              : "-"}
          </div>
        </div>
      ))}
    </div>
  );
};
